import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth'

// Create token provider for the commercetools project
const tokenProvider = new TokenProvider({
  sdkAuth: new SdkAuth({
    host: process.env.NEXT_PUBLIC_CT_AUTH_URL,
    projectKey: process.env.NEXT_PUBLIC_CT_PROJECT_KEY,
    credentials: {
      clientId: process.env.NEXT_PUBLIC_CT_CLIENT_ID,
      clientSecret: process.env.NEXT_PUBLIC_CT_CLIENT_SECRET,
    },
    scopes: process.env.NEXT_PUBLIC_CT_SCOPES?.split(','),
  }),
  fetchTokenInfo: (sdkAuth: any) => sdkAuth.anonymousFlow(),
});

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_CT_API_URL}/${process.env.NEXT_PUBLIC_CT_PROJECT_KEY}/graphql`,
});


const authLink = setContext((_, { headers = {} }) => tokenProvider.getTokenInfo()
  .then((tokenInfo:any) => `${tokenInfo.token_type} ${tokenInfo.access_token}`)
  .then((authorization: any) => ({ headers: { ...headers, authorization } })));


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
