import { ApolloProvider } from '@apollo/client'
import '@assets/chrome-bug.css'
import '@assets/main.css'
import { UserProvider } from '@auth0/nextjs-auth0'
import client from '@commerce/client'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { pageView } from '@lib/events/pageView'
import 'keen-slider/keen-slider.min.css'
import type { AppProps } from 'next/app'
import { default as router, default as Router } from 'next/router'
import NProgress from 'nprogress'
import { FC, useEffect } from 'react'

const Noop: FC = ({ children }) => <>{children}</>

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => {
  NProgress.start()
})

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})

Router.events.on('routeChangeError', () => {
  NProgress.done()
})

export default function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    document.body.classList?.remove('loading')
    router.events.on('routeChangeComplete', pageView)
    return () => {
      router.events.off('routeChangeComplete', pageView)
    }
  }, [])

  return (
    <>
      <ApolloProvider client={client}>
        <UserProvider>
          <Head />
          <ManagedUIContext>
            <Layout pageProps={pageProps}>
              <Component {...pageProps} />
            </Layout>
          </ManagedUIContext>
        </UserProvider>
      </ApolloProvider>
    </>
  )
}
