import config from '@config/seo.json'
import { DefaultSeo } from 'next-seo'
import NextHead from 'next/head'
import { FC } from 'react'
import gtm from './gtm'

const Head: FC = () => {
  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      </NextHead>
      <link
        rel="preload"
        href="/fonts/Icomoon.ttf"
        as="font"
        type="font/ttf"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Bold.ttf"
        as="font"
        type="font/ttf"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Medium.ttf"
        as="font"
        type="font/ttf"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Regular.ttf"
        as="font"
        type="font/ttf"
        crossOrigin=""
      />
      {gtm}
    </>
  )
}

export default Head
