import type {
  Product,
  ProductImage,
  ProductPrice,
} from '@commerce/types/product'

const STORAGE_KEY_FOR_RVP = 'rvp-products-2'

export const addRecentlyViewedProduct = (product: Product) => {
  const normalizedProduct = normalizeProductForStorage(product)
  const storage = window.localStorage
  const productsString = storage.getItem(STORAGE_KEY_FOR_RVP)
  let products: any[] = []
  if (productsString) {
    products = JSON.parse(productsString)

    // check if product is already in the recently viewed list. If yes, remove it from its index
    const existingIndex = products.findIndex(
      (p) => p.id === normalizedProduct.id
    )
    if (existingIndex > -1) {
      products.splice(existingIndex, 1)
    }
    // remove last element if there are already 10 recently viewed products
    if (products.length > 8) {
      products.pop()
    }
    products.unshift(normalizedProduct)
  } else {
    products.push(normalizedProduct)
  }
  storage.setItem(STORAGE_KEY_FOR_RVP, JSON.stringify(products))
}

export const getRecentlyViewedProducts = (): Product[] => {
  const storage = window.localStorage
  let products: any[] = []
  const productsString = storage.getItem(STORAGE_KEY_FOR_RVP)
  if (productsString) {
    products = JSON.parse(productsString)
    // temporary fix for the old version of the storage
    const oldProducts = products.filter((p) => p?.prices === undefined)
    if (oldProducts.length > 0) {
      storage.removeItem(STORAGE_KEY_FOR_RVP)
      products = []
    }
  }
  return products
}

const normalizeProductForStorage = (
  product: Product
): {
  id: string
  name?: string | null
  images?: ProductImage[]
  slug?: string | null
  prices: ProductPrice[]
  masterVariant: {
    isOnStock: boolean
  }
  'product-attribute-short-description'?: string
} => {
  return {
    id: product.id,
    name: product.name,
    images: product.images,
    slug: product.slug,
    prices: product.prices,
    masterVariant: {
      isOnStock: product?.masterVariant?.isOnStock || false,
    },
    'product-attribute-short-description':
      product['product-attribute-short-description'],
  }
}

export const storePostcode = (code: string) => {
  const storage = window.localStorage
  if (code) {
    storage.setItem('Postcode', code)
  }
}

export const getPostcode = () => {
  if (typeof window !== 'undefined') {
    const storage = window.localStorage
    const code = storage.getItem('Postcode')
    if (code) return code
    return ''
  }
  return ''
}

export const storeSuburb = (suburb: string) => {
  const storage = window.localStorage
  if (suburb) {
    storage.setItem('Suburb', suburb)
  }
}

export const getSuburb = () => {
  if (typeof window !== 'undefined') {
    const storage = window.localStorage
    const suburb = storage.getItem('Suburb')
    if (suburb) return suburb
    return ''
  }
  return ''
}
